// components/BrandsSearch.tsx
import React, { useState } from 'react';
import { TextField, Paper, List, ListItem, ListItemText } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { SetBrandAttributeSlugs } from '@/store/slices/brand-slice';
import { useRouter } from 'next/navigation';
import {
    setFirstBrandSlug,
    setHeaderFilter,
} from '@/store/slices/filter-slice';
import { SetClearCategoryFilter } from '@/store/slices/categorySlice';

interface SearchProps {
    onSearch: (data: { query: string; suggestions: string[] }) => void;
    suggestions: string[];
    closeClick?: any;
}

const BrandsSearch: React.FC<SearchProps> = ({
    onSearch,
    suggestions,
    closeClick,
}) => {
    const [query, setQuery] = useState('');
    const dispatch = useDispatch<AppDispatch>();
    const router = useRouter();
    const brandList = useSelector(
        (state: any) => state.brand_store.selectedBrandsData
    );

    const filteredData = brandList?.filter((item: any) =>
        item?.value?.toLowerCase().includes(query?.toLowerCase())
    );

    const sortedData = filteredData?.sort((a: any, b: any) => {
        const nameA = a.value.toLowerCase()?.trim();
        const nameB = b.value.toLowerCase()?.trim();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });

    let prevFirstChar = '';

    return (
        <>
            <div className="w-[90%] border-2 border-[#3b3b3b]/10 rounded-md">
                <div className="flex justify-center items-center">
                    <div className="h-full mr-3 pl-2 pt-1">
                        <Search />
                    </div>
                    <div className="search-brands w-full">
                        <TextField
                            id="brandSearch"
                            className="w-[100%]"
                            placeholder="Search Brands"
                            variant="standard"
                            fullWidth
                            InputProps={{
                                sx: {
                                    '& input::placeholder': {
                                        fontSize: '14px',
                                    },
                                    height: '32px',
                                    fontSize: '14px'
                                },
                            }}
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                    </div>
                </div>
                {/* {suggestions.length > 0 && (
          <List className="">
            {suggestions.map(suggestion => (
              <ListItem key={suggestion} onClick={() => handleSuggestionClick(suggestion)}>
                <ListItemText primary={suggestion} />
              </ListItem>
            ))}
          </List>
        )} */}
            </div>
            <>
                <div className="fs-20 font-bold mt-4 mb-2">All Brands</div>
                <ul className="h-[40vh] overflow-auto w-[90%] mb-10 ">
                    {/* list of all brands in top brands */}
                    {sortedData?.map(
                        (item: {
                            value: string;
                            _id: string;
                            slug: string;
                        }) => {
                            let firstChar = item.value[0].toUpperCase();
                            if (!firstChar.match(/[A-Z]/)) {
                                firstChar = '';
                            }
                            const isNewGroup = firstChar !== prevFirstChar;
                            prevFirstChar = firstChar;

                            return (
                                <li
                                    className="my-3 cursor-pointer fs-18"
                                    key={item._id}
                                >
                                    <div
                                        onClick={
                                            isNewGroup
                                                ? undefined
                                                : (e) => {
                                                      dispatch(
                                                          setHeaderFilter(true)
                                                      );
                                                      dispatch(
                                                          setFirstBrandSlug(
                                                              item.slug
                                                          )
                                                      );
                                                      router.push(
                                                          `/product-listing?page=1&brand=${item.slug}`
                                                      );
                                                      dispatch(
                                                          SetBrandAttributeSlugs(
                                                              [item.slug]
                                                          )
                                                      );
                                                      dispatch(
                                                          SetClearCategoryFilter()
                                                      );
                                                      e.stopPropagation();
                                                      closeClick();
                                                  }
                                        }
                                    >
                                        {/* {isNewGroup && (
                                            <div className="mt-4 mb-4">
                                                <div className="font-bold">
                                                    {firstChar}
                                                </div>
                                            </div>
                                        )} */}
                                        <div
                                            onClick={(e) => {
                                                dispatch(setHeaderFilter(true));
                                                dispatch(
                                                    setFirstBrandSlug(item.slug)
                                                );
                                                router.push(
                                                    `/product-listing?page=1&brand=${item.slug}`
                                                );
                                                dispatch(
                                                    SetBrandAttributeSlugs([
                                                        item.slug,
                                                    ])
                                                );
                                                dispatch(
                                                    SetClearCategoryFilter()
                                                );
                                                e.stopPropagation();
                                                closeClick();
                                            }}
                                        >
                                            <p className='text-sm leading-none'>{item.value}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        }
                    )}
                </ul>
            </>
        </>
    );
};

export default BrandsSearch;
