'use client';

import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MinimizeIcon from '@mui/icons-material/Minimize';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { SetCategoryId } from '@/store/slices/categorySlice';
import {
    setHeaderFilter,
    setIsDiscountFilter,
} from '@/store/slices/filter-slice';
import StoreListDrawer from '@/components/common/store-list-drawer';
import { createEncodedURL } from '@/utils/utilities';

// interface Category {
//   _id: number;
//   name: string;
//   Components: any;
// }
type Anchor = 'left';
interface Category {
    _id: string;
    name: string;
    slug: string;
    populatedChildren: Category[];
    Components: any;
}

// const CategoriesData: Category[] = [
//   {
//     _id: 1,
//     name: "Makeup & Skin",
//     Components: <MakeUpMobComp />,
//   },
//   {
//     _id: 2,
//     name: "Health & Pharmacy",
//     Components: "test-2",
//   },
//   {
//     _id: 3,
//     name: "Wellness & Personal Care",
//     Components: "test-2",
//   },
//   {
//     _id: 4,
//     name: "Fragrance",
//     Components: "test-2",
//   },
//   {
//     _id: 5,
//     name: "Men",
//     Components: "test-2",
//   },
//   {
//     _id: 6,
//     name: "Vitamins & Nutrition",
//     Components: "test-2",
//   },
// ];

const CategoriesComp = ({
    toggleDrawer,
}: {
    toggleDrawer: (
        anchor: Anchor,
        open: boolean
    ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
    const [expanded, setExpanded] = useState<string | null>();
    const [expanded2, setExpanded2] = useState<string | null>();
    const dispatch = useDispatch<AppDispatch>();
    const router = useRouter();
    const categories = useSelector(
        (state: any) => state.category_store.categories
    );

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : null);
        };
    const handleChange2 =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded2: boolean) => {
            setExpanded2(isExpanded2 ? panel : null);
        };

    const [storeOpen, setStoreOpen] = useState(false);
    const handleDrawerOpen = () => {
        setStoreOpen(true);
    };

    const handleDrawerClose = () => {
        setStoreOpen(false);
    };

    return (
        <div>
            {/* FETCHING MAIN CATEGORY */}
            {categories?.map(
                (
                    item: {
                        _id: React.Key | null | undefined;
                        slug: string;
                        name:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                              >
                            | Iterable<React.ReactNode>
                            | React.ReactPortal
                            | null
                            | undefined;
                        populatedChildren: any[];
                    },
                    index: any
                ) => (
                    <Accordion
                        key={item._id}
                        expanded={expanded === `panel${item._id}`}
                        onChange={handleChange(`panel${item._id}`)}
                        className="my-0 border-0 shadow-sm shadow-[#FFFFFF1A] bg-transparent text-white"
                    >
                        <AccordionSummary
                            sx={{ borderColor: '#FFFFFF1A' }}
                            className=" min-h-[50px] "
                            onClick={(e) => {
                                dispatch(setHeaderFilter(true));
                                dispatch(
                                    SetCategoryId({
                                        firstCategoryId: item._id,
                                    })
                                );
                            }}
                            expandIcon={
                                expanded === `panel${item._id}` ? (
                                    <MinimizeIcon sx={{ color: '#fff' }} />
                                ) : (
                                    <AddIcon sx={{ color: '#fff' }} />
                                )
                            }
                        >
                            <p
                                key={item._id}
                                className="font-bold text-[#fff] fs-18 py-2 "
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        SetCategoryId({
                                            firstCategoryId: item._id,
                                        })
                                    );
                                    dispatch(setHeaderFilter(true));
                                    dispatch(setIsDiscountFilter(false));
                                    toggleDrawer('left', false)(e);
                                    const encodedUrl = createEncodedURL(
                                        'product-listing',
                                        {
                                            page: 1,
                                            first: item.slug,
                                        }
                                    );
                                    router.push(encodedUrl);
                                }}
                            >
                                {item.name}
                            </p>
                        </AccordionSummary>
                        <AccordionDetails className="pt-0 mt-0">
                            {/* Sub Accordion */}
                            {/* FETCHING SUB CATEGORY */}
                            {item.populatedChildren.map((subcategory: any) => (
                                <Accordion
                                    key={subcategory._id}
                                    expanded={
                                        expanded2 === `panel${subcategory._id}`
                                    }
                                    onChange={handleChange2(
                                        `panel${subcategory._id}`
                                    )}
                                    className="cursor-pointer my-0 border-0 shadow-sm shadow-[#FFFFFF1A] bg-transparent text-white"
                                >
                                    <AccordionSummary
                                        sx={{ borderColor: '#FFFFFF1A' }}
                                        className=" min-h-[50px] "
                                        expandIcon={
                                            expanded2 ===
                                            `panel${subcategory._id}` ? (
                                                <MinimizeIcon
                                                    sx={{ color: '#fff' }}
                                                />
                                            ) : (
                                                <AddIcon
                                                    sx={{ color: '#fff' }}
                                                />
                                            )
                                        }
                                    >
                                        <p
                                            key={subcategory._id}
                                            className="font-bold text-[#fff] fs-18 py-2 cursor-pointer capitalize"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                dispatch(setHeaderFilter(true));
                                                dispatch(
                                                    setIsDiscountFilter(false)
                                                );
                                                toggleDrawer('left', false)(e);
                                                const encodedUrl =
                                                    createEncodedURL(
                                                        'product-listing',
                                                        {
                                                            page: 1,
                                                            first: item.slug,
                                                            second: subcategory.slug,
                                                        }
                                                    );
                                                router.push(encodedUrl);
                                            }}
                                        >
                                            {subcategory.name?.toLowerCase()}
                                        </p>
                                    </AccordionSummary>
                                    <AccordionDetails className="pt-0 mt-0">
                                        <ul>
                                            {/* FETCHING SUBCHILD CATEGORY */}
                                            {subcategory.populatedChildren.map(
                                                (subSubCategory: any) => {
                                                    return (
                                                        <div
                                                            key={
                                                                subSubCategory._id
                                                            }
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                toggleDrawer(
                                                                    'left',
                                                                    false
                                                                );
                                                                dispatch(
                                                                    setHeaderFilter(
                                                                        true
                                                                    )
                                                                );
                                                                dispatch(
                                                                    setIsDiscountFilter(
                                                                        false
                                                                    )
                                                                );
                                                                e.preventDefault();
                                                                toggleDrawer(
                                                                    'left',
                                                                    false
                                                                )(e);
                                                                const encodedUrl =
                                                                    createEncodedURL(
                                                                        'product-listing',
                                                                        {
                                                                            page: 1,
                                                                            first: item.slug,
                                                                            second: subcategory.slug,
                                                                            third: subSubCategory.slug,
                                                                        }
                                                                    );
                                                                router.push(
                                                                    encodedUrl
                                                                );
                                                            }}
                                                        >
                                                            <li
                                                                className="py-5 text-xs leading-none border-b-[1px] border-[#FFFFFF1A] cursor-pointer capitalize"
                                                                key={
                                                                    subSubCategory._id
                                                                }
                                                            >
                                                                {
                                                                    subSubCategory.name?.toLowerCase()
                                                                }
                                                            </li>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                            {/* Sub Accordion END */}
                        </AccordionDetails>
                    </Accordion>
                )
            )}
            <Link
                onClick={toggleDrawer('left', false)}
                href={'https://www.instagram.com/aakashlifeofficial/'}
                target='_blank'
            >
                <p className=" text-[#fff] fs-18 px-4 py-5 border-b-[1px] border-[#FFFFFF1A] ">
                    Inspire Me
                </p>
            </Link>
            <Link onClick={toggleDrawer('left', false)} href={'/offers'}>
                <p className=" text-[#fff] fs-18 px-4 py-5 border-b-[1px] border-[#FFFFFF1A] ">
                    Offers
                </p>
            </Link>
            <Link
                onClick={toggleDrawer('left', false)}
                href={'/user/mobile-prescription'}
            >
                <p className=" text-[#fff] fs-18 px-4 py-5 border-b-[1px] border-[#FFFFFF1A] ">
                    Prescription
                </p>
            </Link>
            <div onClick={handleDrawerOpen}>
                <p className=" text-[#fff] fs-18 px-4 py-5 border-b-[1px] border-[#FFFFFF1A] ">
                    Find a Store
                </p>
            </div>
            <Link
                onClick={toggleDrawer('left', false)}
                href={'/user/my-orders-mob'}
            >
                <p className=" text-[#fff] fs-18 px-4 py-5 border-b-[1px] border-[#FFFFFF1A] ">
                    Track Orders
                </p>
            </Link>
            <Link onClick={toggleDrawer('left', false)} href={'/contact'}>
                <p className=" text-[#fff] fs-18 px-4 py-5 border-b-[1px] border-[#FFFFFF1A] ">
                    Help Center
                </p>
            </Link>

            <StoreListDrawer
                Heading="Our Stores"
                isOpen={storeOpen}
                onClose={handleDrawerClose}
                RadioNdBtn={true}
            />
        </div>
    );
};

export default CategoriesComp;
